import { default as aboutznbfisZO1uMeta } from "/home/user/business/scaleuprobotics/tech/website_frontend/website/pages/about.vue?macro=true";
import { default as contactV4YJLaD0csMeta } from "/home/user/business/scaleuprobotics/tech/website_frontend/website/pages/contact.vue?macro=true";
import { default as designpcsg9oSvxDMeta } from "/home/user/business/scaleuprobotics/tech/website_frontend/website/pages/design.vue?macro=true";
import { default as highered0s5mL0foMjMeta } from "/home/user/business/scaleuprobotics/tech/website_frontend/website/pages/highered.vue?macro=true";
import { default as indexp3IBTDr9SCMeta } from "/home/user/business/scaleuprobotics/tech/website_frontend/website/pages/index.vue?macro=true";
import { default as privacyDEQrIrhiifMeta } from "/home/user/business/scaleuprobotics/tech/website_frontend/website/pages/privacy.vue?macro=true";
import { default as schools58Wil6YBgYMeta } from "/home/user/business/scaleuprobotics/tech/website_frontend/website/pages/schools.vue?macro=true";
export default [
  {
    name: aboutznbfisZO1uMeta?.name ?? "about",
    path: aboutznbfisZO1uMeta?.path ?? "/about",
    meta: aboutznbfisZO1uMeta || {},
    alias: aboutznbfisZO1uMeta?.alias || [],
    redirect: aboutznbfisZO1uMeta?.redirect,
    component: () => import("/home/user/business/scaleuprobotics/tech/website_frontend/website/pages/about.vue").then(m => m.default || m)
  },
  {
    name: contactV4YJLaD0csMeta?.name ?? "contact",
    path: contactV4YJLaD0csMeta?.path ?? "/contact",
    meta: contactV4YJLaD0csMeta || {},
    alias: contactV4YJLaD0csMeta?.alias || [],
    redirect: contactV4YJLaD0csMeta?.redirect,
    component: () => import("/home/user/business/scaleuprobotics/tech/website_frontend/website/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: designpcsg9oSvxDMeta?.name ?? "design",
    path: designpcsg9oSvxDMeta?.path ?? "/design",
    meta: designpcsg9oSvxDMeta || {},
    alias: designpcsg9oSvxDMeta?.alias || [],
    redirect: designpcsg9oSvxDMeta?.redirect,
    component: () => import("/home/user/business/scaleuprobotics/tech/website_frontend/website/pages/design.vue").then(m => m.default || m)
  },
  {
    name: highered0s5mL0foMjMeta?.name ?? "highered",
    path: highered0s5mL0foMjMeta?.path ?? "/highered",
    meta: highered0s5mL0foMjMeta || {},
    alias: highered0s5mL0foMjMeta?.alias || [],
    redirect: highered0s5mL0foMjMeta?.redirect,
    component: () => import("/home/user/business/scaleuprobotics/tech/website_frontend/website/pages/highered.vue").then(m => m.default || m)
  },
  {
    name: indexp3IBTDr9SCMeta?.name ?? "index",
    path: indexp3IBTDr9SCMeta?.path ?? "/",
    meta: indexp3IBTDr9SCMeta || {},
    alias: indexp3IBTDr9SCMeta?.alias || [],
    redirect: indexp3IBTDr9SCMeta?.redirect,
    component: () => import("/home/user/business/scaleuprobotics/tech/website_frontend/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: privacyDEQrIrhiifMeta?.name ?? "privacy",
    path: privacyDEQrIrhiifMeta?.path ?? "/privacy",
    meta: privacyDEQrIrhiifMeta || {},
    alias: privacyDEQrIrhiifMeta?.alias || [],
    redirect: privacyDEQrIrhiifMeta?.redirect,
    component: () => import("/home/user/business/scaleuprobotics/tech/website_frontend/website/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: schools58Wil6YBgYMeta?.name ?? "schools",
    path: schools58Wil6YBgYMeta?.path ?? "/schools",
    meta: schools58Wil6YBgYMeta || {},
    alias: schools58Wil6YBgYMeta?.alias || [],
    redirect: schools58Wil6YBgYMeta?.redirect,
    component: () => import("/home/user/business/scaleuprobotics/tech/website_frontend/website/pages/schools.vue").then(m => m.default || m)
  }
]